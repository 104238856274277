alertify.set({
  buttonReverse: true,
  labels : {
    ok : 'Да',
    cancel : 'Нет'
  }
});

function initCommonScripts() {
  'use strict';

  // CONST
  window.PSWP = {
    TMPL: document.getElementById('pswp_template'),
    UI: PhotoSwipeUI_Default
  };

  var $items = $('.js-pswp-gallery-item');
  var images = [];
  var settings = {
    shareEl: false
  };

  $.each($items, function(key, value) {
    var $item = $(this);
    var img = new Image();
    var itemName = $item.data('item-name');
    var itemHref = $item.data('item-href');

    $.data(this, 'image-id', key);

    img.src = $(this).attr('href');
    img.onload = function() {
      images[key] = {
        src: img.src,
        w: this.width,
        h: this.height
      };

      if (itemName) images[key].title = itemName;
    };
  });

  $items.off('click').on('click', function(e) {
    e.preventDefault();
    settings.index = $.data(this, 'image-id');

    var pswp = new PhotoSwipe(PSWP.TMPL, PSWP.UI, images, settings);

    pswp.init();
  });

  $(document.body)
    .on('yiiListViewUpdated', function(){
      var $catalog = $('.breadcrumbs');
      // var scrollSpeed = Math.abs( $(window).scrollTop() ) * 0.3;
      // $(window).scrollTo( $catalog, {
      //   duration: scrollSpeed
      // });

      $('select').selectric({ disableOnMobile: false });
      $(':checkbox, :radio').not('.hidden').forms();

      tooltipScripts();

      $('.filter-block .panel-toggler').click(function() {
        $(this).closest('.filter-block').toggleClass('expanded');
      });

      // if ( isMobile && $('#product-filter').length ) {
      //   $('#product-filter').perfectScrollbar();
      // }

      if (Retina.isRetina()) {
        Retina.init(root);
      }
    })
    .on('overlayLoaderShow', function(e, $node) {
      $node.find('.autofocus-inp').focus();
    });

  var tooltipScripts = function() {
    $('.tooltip-anchor').click(function(e) {
      e.preventDefault();
      $(this).closest('.tooltip').addClass('active').find('.tooltip-body').stop(true, true).fadeIn();

      $(window).on('scroll.tooltip', function(e) {
        if ( $('.tooltip.active .tooltip-body').hasClass('pronation-tooltip') ) {
          $('.tooltip-body').stop(true, true).hide();
        } else {
          $('.tooltip-body').stop(true, true).fadeOut();
        }
        $('.tooltip.active').removeClass('active');
        $(window).off('scroll.tooltip');
      });
    });

    $('.tooltip').click(function(e) {
      // e.preventDefault();
      e.stopPropagation();
    });

    $('.tooltip .close').click(function(e) {
      e.preventDefault();
      if ( $(this).closest('.tooltip-body').hasClass('pronation-tooltip') ) {
        $(this).closest('.tooltip-body').stop(true, true).hide();
      }
      $(this).closest('.tooltip-body').stop(true, true).fadeOut().closest('.tooltip').removeClass('active');
    });

    $('body').click(function(e) {
      if ( $(e.target).closest('.tooltip-body').length ) return;
      if ( $('.tooltip.active .tooltip-body').hasClass('pronation-tooltip') ) {
        $('.tooltip-body').stop(true, true).hide();
      }
      $('.tooltip-body').stop(true, true).fadeOut().closest('.tooltip').removeClass('active');
    });
  }
  tooltipScripts();

  //RetailCrm инициализация
  (function(_,r,e,t,a,i,l){_['retailCRMObject']=a;_[a]=_[a]||function(){(_[a].q=_[a].q||[]).push(arguments)};_[a].l=1*new Date();l=r.getElementsByTagName(e)[0];i=r.createElement(e);i.async=!0;i.src=t;l.parentNode.insertBefore(i,l)})(window,document,'script','https://collector.retailcrm.pro/w.js','_rc');

  _rc('create', 'RC-16825603807-2');
  _rc('send', 'pageView');
}

$(function() {
  var root = (typeof exports === 'undefined' ? window : exports);

  initCommonScripts();

  $('select').selectric({ disableOnMobile: false });
  $(':checkbox, :radio').not('.hidden').forms();

  // OverlayLoader init
  $(document).on('click.overlay', '.js-overlay', function(e) {
    e.preventDefault();
    var popup = $($(this).attr('href'));
    $.overlayLoader(true, {node: popup, onHide: function(e) {
      popup.removeClass('flipped');
    }});
  });
  $(document).on('click', '.popup .close, .popup .close-btn', function(e){
    e.preventDefault();
    var target = $(this).closest('.popup');
    $.overlayLoader(false, target);
  });

  var picsLimit = 6,
      feed;
  // if ( $(window).width() <= 1200 && $(window).width() > 800  ) picsLimit = 10;
  var instafeedInit = function(userId, accessToken) {
    feed = new Instafeed({
      clientId: '9f5c4436b4774771a9ccd7db93cb73f2',
      get: 'user',
      userId: userId,
      // filter: function(image) { // Это должно быть только на страницах брендов
      //   return image.tags.indexOf('TAG_NAME') >= 0; // TAG_NAME надо заменить на тег-имя бренда
      // },
      accessToken: accessToken,
      limit: picsLimit,
      resolution: 'low_resolution',
      template: '<a href="{{link}}" target="_blank"><img src="{{image}}" alt="" /></a>',
      after: function() {
        var newPage = $('<div class="instapage"></div>'),
          newPics = $('#instafeed a:gt(' + (picsLimit - 1) + ')').detach();
        newPage.append( newPics );
        if ( newPage.find('a').length ) {
          $('.instawidget').append( newPage );
        }
      }
    });
    feed.run();

    setTimeout(function() {
      feed.next();
    }, 1000);

    $('.inst-next-page').click(function(e) {
      e.preventDefault();
      feed.next();
      var activePage = $('.instapage.active'),
        pageWidth = $('#instafeed').width(),
        widget = $('.instawidget'),
        currentPosition = parseInt( widget.css('left'), 10 );
      if ( $('.instapage.active').next().length ) {
        $('.instapage.active').removeClass('active').next().addClass('active');
        widget.css( 'left', currentPosition - pageWidth );
      }
      if ( $('.instapage.active').prev().length ) {
        $('.inst-prev-page').removeClass('disabled');
      }
      if ( feed.hasNext() || $('.instapage.active').next().length ) {
        $('.inst-next-page').removeClass('disabled');
      } else {
        $('.inst-next-page').addClass('disabled');
      }
    });

    $('.inst-prev-page').click(function(e) {
      e.preventDefault();
      var activePage = $('.instapage.active'),
        pageWidth = $('#instafeed').width(),
        widget = $('.instawidget'),
        currentPosition = parseInt( widget.css('left'), 10 );
      if ( $('.instapage.active').prev().length ) {
        $('.instapage.active').removeClass('active').prev().addClass('active');
        widget.css( 'left', currentPosition + pageWidth );
      }
      if ( !$('.instapage.active').prev().length ) {
        $('.inst-prev-page').addClass('disabled');
      }
      if ( $('.instapage.active').next().length ) {
        $('.inst-next-page').removeClass('disabled');
      }
    });
  }

  window.instafeedInit = instafeedInit;

  $('input[type="tel"], .phone-input').each(function() {
    setPhoneMask($(this));
  });

  function setPhoneMask($input) {
    var defaultPlaceholder = $input.attr('placeholder') || '';
    var defaultPlaceholderMask = '+7 (___) ___-__-__';
    var phoneMask = '+7 (000) 000-00-00';
    var pasted = false;

    $input.on('focus', function() {
      $input.attr('placeholder', defaultPlaceholderMask);
    }).on('blur', function() {
      $input.attr('placeholder', defaultPlaceholder);
    });

    $input.on('paste drop', function() {
      var isEmpty = !$input.val();
      var element = $input.get(0);
      var selectionLength = element.selectionEnd - element.selectionStart;

      if (!isEmpty && $input.val().length !== selectionLength) {
        return;
      }

      pasted = true;
    });

    $input.on('input', function(e) {
      var value = $input.val();

      if (value.startsWith('8')) {
        if (pasted && value.length !== 11) {
          pasted = false;
          return;
        }

        pasted = false;

        e.stopPropagation();

        value = value.replace('8', '+7');
        $input.val(value);
      }
    });

    $input.mask(phoneMask);
  }

  $('.js-toggle-phone-mask').change(function() {
    $this = $(this);
    $inp = $this.closest('.form-field').find('.inp');
    if ($this.prop('checked')) {
      $inp.unmask().val('').focus();
    } else {
      $inp.val('');
      setPhoneMask($inp);
      $inp.focus();
    }
  });

  $('body').on('click', '.spinner-up, .spinner-down', function() {
    var self = $(this),
      spinner = $(this).closest('.spinner'),
      input = spinner.find('.inp'),
      step = input.data('step') ? input.data('step') : 1,
      zero = input.data('zero') ? input.data('zero') : false,
      max = input.data('max') ? input.data('max') : false,
      value = parseInt( input.val(), 10 );
    if ( self.hasClass('spinner-up') ) {
      if ( !max || value + step <= max ) {
        value += step;
      }
    } else {
      if ( zero ) {
        if ( value >= step ) {
          value -= step;
        }
      } else {
        if ( value > step ) {
          value -= step;
        }
      }
    }
    input.val(value).trigger('change');
    // Imigize.integration.runlab.updateBasket();
  });

  $('body').on('keydown', '.spinner .inp', function(e) {
    var inp = $(this);
    // Разрешаем: backspace, delete, tab и escape
    if ( e.keyCode == 46 || e.keyCode == 8 || e.keyCode == 9 || e.keyCode == 27 ||
        // Разрешаем: Ctrl+A
      (e.keyCode == 65 && e.ctrlKey === true) ||
        // Разрешаем: home, end, влево, вправо
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      return;
    } else {
      // Убеждаемся, что это цифра, и останавливаем событие keypress
      if ( (e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105 ) ) {
        e.preventDefault();
      }
    }
  });

  $('body').on('keyup paste', '.spinner .inp', function(e) {
    var inp = $(this),
        max = inp.data('max') || false,
        value = parseInt( inp.val(), 10 );
    if ( value == 0 ) {
      inp.val(1);
    }
    if ( max && value > max ) {
      inp.val( max );
    }
  });

  $('#content').on('click', '#js-wrapper-basket .spinner-up', function() {
    var spinner = $(this).closest('.spinner').find('.inp'),
        max = spinner.data('max'),
        value = spinner.val();
    if ( value == max ) {
      alertify.alert('Ошибка. На складах осталось всего ' + max + ' шт. товара');
    }
  });

  // $('body').append( $('<div class="go-top-wrapper"><a href="#" class="go-top"></a></div>') );

  // $(window).scroll(function() {
  //   var scrollTop = $(window).scrollTop();
  //   if ( scrollTop > 300 ) {
  //     $('.go-top').addClass('visible');
  //   } else {
  //     $('.go-top').removeClass('visible');
  //   }
  // });

  // $('.go-top').click(function(e){
  //   e.preventDefault();
  //   e.stopPropagation();
  //   $('body').scrollTo( 0, 300 );
  // });

  if (Retina.isRetina()) {
    Retina.init(root);
  }

  // $('.sizes-chart-tabs').each(function() {
  //   var self = $(this),
  //       headingWidth = self.find('.sizes-chart-header .sizes-chart').width();
  //   self.find('.sizes-chart-header').width( headingWidth );
  //   self.find('.sizes-chart-body').css('marginLeft', headingWidth);
  // });


  $('.j-sizes-table').each(function() {
    var $table = $(this).find('table');
    var highlightedClass = 'highlighted';
    var $sizesHead = $(this).find('.sizes-chart');
    var $sizesBody = $(this).find('.sizes-chart-body');

    $table
      .on('mouseenter', 'td', function() {
        var j = $(this).index() + 1;
        $table.find('tr td:nth-child(' + j + ')').addClass(highlightedClass);
      })
      .on('mouseleave', 'td', function() {
        $table.find('td').removeClass(highlightedClass);
      })

    if ( $sizesHead.length && $sizesBody.length ) {
      $sizesHead
        .on('mouseenter', 'th', function() {
          var j = $(this).closest('tr').index() + 1;
          $sizesBody.find('tr:nth-child(' + j + ') td').addClass(highlightedClass);
        })
        .on('mouseleave', 'th', function() {
          $sizesBody.find('td').removeClass(highlightedClass);
        });
      $sizesBody
        .on('mouseenter', 'td', function() {
          var j = $(this).closest('tr').index() + 1;
          $sizesHead.find('tr:nth-child(' + j + ') th').addClass(highlightedClass);
        })
        .on('mouseleave', 'td', function() {
          $sizesHead.find('th').removeClass(highlightedClass);
        });
    }
  });

  $('.js-video-link').click(function(e) {
    e.preventDefault();
    console.log();

    var $self = $(this),
        link = $self.attr('href'),
        $iframe = $('<iframe src="' + link + '" frameborder="0" allowfullscreen></iframe>'),
        $popup = $('<div class="popup video-popup"><a href="" class="close"></a></div>').append( $iframe );

    $.overlayLoader(true, { node: $popup, onShow: function() {
      $iframe.attr('src', link + '?autoplay=1');
    }, onHide: function() {
      $popup.remove();
    }});
  });

  $('.js-scroll-to').on('click', function(e) {
    e.preventDefault();

    var $link = $(this);
    var $anchor = $($link.attr('href'));
    var speed = $link.data('speed') || 150;
    var offset = $link.data('offset') || -$('header').height() - 15;

    $('body, #site-container').scrollTo($anchor, {duration: speed, offset: offset});
  });

  var hash = window.location.hash;
  setTimeout(function() {
    if ( hash.length ) {
      $(window).scrollTo( $(hash), {duration: 0, offset: -$('header').height() - 15} );
    }
  }, 200);

  $('.bottom-menu .menu--caption').click(function(e) {
    $(this).closest('.bottom-menu').toggleClass('is-active');
  });

  $('.js-one-row-vitrine').each(function() {
    var size = 4,
        $this = $(this),
        $prev = $this.closest('.one-row-vitrine-wrapper').find('.vitrine-prev'),
        $next = $this.closest('.one-row-vitrine-wrapper').find('.vitrine-next');

    if ( $(window).width() <= 1200 ) {
      size = 3;
    }

    if ( $(window).width() <= 480 ) {
      size = 1;
    }

    $(this).tosrus({
      slides: {
        visible: size
      },
      buttons: {
        next: $next,
        prev: $prev
      }
    });
  });

  $('.product-card--section-caption').click(function() {
    $(this).closest('.product-card--section').toggleClass('is-expanded');
  });

  $('.product-card-aside--block .subcaption').click(function() {
    $(this).closest('.product-card-aside--block').toggleClass('is-expanded');
  });

  $('.js-label-link-fix').on('click', function () {
    if ($(this).attr('target') == '_blank'){
      window.open( this.href );
    } else {
      document.location.href = this.href;
    }
  });

  var $spoiler = $('.js-product-description-spoiler');
  $spoiler.css('maxHeight', 'none');
  if ($spoiler.height() < 300) {
    $spoiler.removeClass('is-collapsible');
  }
  $spoiler.attr('style', '');

  $('.js-toggle-product-description').click(function() {
    $(this).closest('.js-product-description-spoiler').toggleClass('is-expanded');
  });

  $('.main-mobile-menu').on('click.js-m-menu', 'a', function(e) {
    e.preventDefault();

    var $anchor = $(this);
    var $parent = $anchor.parent();
    var hasDropdown = $parent.hasClass('has-dropdown');
    var isHovered = $parent.hasClass('is-hovered');
    var link = $anchor.attr('href');

    $parent.siblings().removeClass('is-hovered');

    if (!hasDropdown) {
      location.href = $anchor.attr('href');
    } else {
      if (isHovered && link) {
        location.href = link;
      } else {
        $parent.toggleClass('is-hovered');
      }
    }
  });

  /*Events page Calendar*/
  // var eventsCalendar = document.querySelector('.js-date-month-picker'),
  //     nextArrow = $('.js-month-next'),
  //     prevArrow = $('.js-month-prev');

  // nextArrow.on('click', function() {
  //   eventsCalendar.stepUp(1);
  // });

  // prevArrow.on('click', function() {
  //   eventsCalendar.stepDown(1);
  // });

  // $('.js-month-prev, .js-month-next').on('click', function() {
  //   if( eventsCalendar.value == eventsCalendar.min ) {
  //     prevArrow.addClass('inactive');
  //   } else if (eventsCalendar.value == eventsCalendar.max)  {
  //     nextArrow.addClass('inactive');
  //   }
  //   else {
  //     prevArrow.removeClass('inactive');
  //     nextArrow.removeClass('inactive');
  //   }
  // });
  /*------------------*/

});

