$(function() {
    var b = $(".xma-offcanvas-menu .menu").height() || 300;
    $(".xma-btn-mobile-menu").on("click", function() {
        if ($("body").hasClass("xma-btn-mobile-menu-open")) {
            $("body").toggleClass("xma-btn-mobile-menu-open").attr("style", "overflow:auto");
            $(".xma-container-mobile-menu").hide();
            b = 300
        } else {
            $("body").toggleClass("xma-btn-mobile-menu-open").attr("style", "overflow:hidden");
            $(".xma-container-mobile-menu").show();
            b = $(".xma-offcanvas-menu .menu").height()
        }
    });
    $(".xma-offcanvas-menu .xma-offcanvas-submenu").each(function() {
        $(this).prepend('<li class="back-btn"><a href="#">Назад</a></li>');
        var c = $(this).parent("li").attr("data-catname") || "",
            d = $(this).parent("li").siblings("li.cat-title").text() + " -" || "";
        if (c !== "") {
            $(this).prepend('<li class="cat-title">' + d + " " + c + '</li><li class="xma-offcanvas-divider"></li>')
        }
    });
    var a = $(".has-children .sub-menu-toggle, .has-children span a");
    $(".xma-offcanvas-menu .xma-offcanvas-submenu .back-btn").on("click", function(f) {
        var k = this,
            j = $(k).parent(),
            i = $(k).parent().parent().siblings().parent(),
            h = $(k).parents(".menu");
        j.removeClass("in-view").css({
            "max-height": "",
            "overflow-y": "",
            "overflow-x": ""
        }), i.removeClass("off-view").css({
            "max-height": "80vh",
            "overflow-y": "scroll",
            "overflow-x": "hidden"
        }), "menu" === i.attr("class") ? h.css({
            "max-height": "",
            "overflow-y": "",
            "overflow-x": "",
            height: b
        }) : h.css("height", i.height()), f.preventDefault()
    });
    a.on("click", function(f) {
        var i = this,
            h = $(i).parent().parent().parent(),
            g = $(i).parents(".menu");
            
            f.preventDefault();
        return h.addClass("off-view").css({
            "max-height": "",
            "overflow-y": "",
            "overflow-x": ""
        }), $(i).parent().parent().find("> .xma-offcanvas-submenu").addClass("in-view").css({
            "max-height": "80vh",
            "overflow-y": "scroll",
            "overflow-x": "hidden"
        }), g.css("height", $(i).parent().parent().find("> .xma-offcanvas-submenu").height()), f.preventDefault(), !1
    })
});